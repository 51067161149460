import "./main.css";
import { Elm } from "./Main.elm";
import registerServiceWorker from "./registerServiceWorker";

const flags = {
  apiUrl: process.env.ELM_APP_API_URL
};

Elm.Main.init({
  node: document.getElementById("root"),
  flags: flags
});

registerServiceWorker();
